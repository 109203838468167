import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Col } from "react-bootstrap"
import * as styles from "./contact.module.scss"
import SidebarMenu from "../Sidebar/SidebarMenu"
import Header from "../Header/Header"
import ContactDetails from "./ContactDetails/ContactDetails"
import ContactForm from "./ContactForm/ContactForm"
import KeyContacts from "./KeyContacts/KeyContacts"
import ContactLocation from "./ContactLocation/ContactLocation"
import Footer from "../Homepage/Footer/Footer"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "Hero/hero_contact.webp" }) {
      childImageSharp {
        fluid(maxWidth: 6000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const Contact = () => {
  const { image } = useStaticQuery(getImage)

  return (
    <div className={styles.contactPage__container}>
      <SidebarMenu name="CONTACT"></SidebarMenu>
      <div>
        <Header image={image.childImageSharp.fluid} pageName="CONTACT" />
        <div className={styles.mainDivStyle}>
          <div className={styles.row}>
            <Col xs={12} md={6} className={styles.divForm}>
              <ContactForm />
            </Col>
            <Col xs={12} md={6} className={styles.divDetails}>
              <ContactDetails />
            </Col>

            <Col xs={12} md={7} className={styles.divForm_mobile}>
              <ContactForm />
            </Col>
            <Col xs={12} md={12} className={styles.divDetails_mobile}>
              <ContactDetails />
            </Col>
          </div>

          <div className={styles.row}>
            <KeyContacts />
          </div>
          <div className={styles.row}>
            <ContactLocation />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Contact
