import React from "react"
import "../ContactLocation/ContactLocation.scss"
import contact from "../../../images/Contact/harta2.webp"
import Image from "react-bootstrap/Image"
import { useIntl } from "gatsby-plugin-intl"

const ContactLocation = () => {
  const intl = useIntl()

  return (
    <div className="contact_location_div">
      <h1 className="contactLocationTitle">
        {intl.formatMessage({ id: "contact.contactLocation.supply" })}
      </h1>
      <div className="divImage">
        <Image src={contact} alt="Contact Location" className="center" fluid />
      </div>
    </div>
  )
}

export default ContactLocation
