import React from "react"
import "../KeyContacts/KeyContacts.scss"
import TeamMember from "../../AboutUs/AbouUsTeam/TeamMember"
import { teamMembers } from "../../../utilities/constants"
import { useIntl } from "gatsby-plugin-intl"

const KeyContacts = () => {
  const intl = useIntl()

  return (
    <div className="key__contact-container">
      <h1 className="keyContactTitle">
        {intl.formatMessage({ id: "contact.keyContacts.key" })}
      </h1>
      <div className="team__members-container">
        {teamMembers.map((member, i) => {
          return (
            <TeamMember
              key={i}
              name={member.name}
              position={member.position}
              phone={member.phone}
              mail={member.mail}
            />
          )
        })}
      </div>
    </div>
  )
}

export default KeyContacts
