import React from "react"
import "./team.style.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { useIntl } from "gatsby-plugin-intl"

const TeamMember = ({ name, position, phone, mail }) => {
  const intl = useIntl()
  return (
    <div className="member__container">
      <div className="member__picture">
        <FontAwesomeIcon
          icon={faUser}
          color="#707070"
          className="font-awesome__user"
        />
      </div>
      <div className="member__info">
        <p className="member__name">{name}</p>
        <p className="member__position">
          {intl.formatMessage({ id: "aboutUs.aboutUsTeamMember." + position })}
        </p>
        <p className="member__phone">{phone}</p>
        <p className="member__mail">{mail}</p>
      </div>
    </div>
  )
}

export default TeamMember
