import React from "react"
import "../ContactDetails/ContactDetails.scss"
import { useIntl } from "gatsby-plugin-intl"

const ContactDetails = () => {
  const intl = useIntl()
  return (
    <div>
      <table className="tableStyle">
        <tbody>
          <tr>
            <td>
              <span className="detailsName">
                {intl.formatMessage({ id: "contact.contactDetails.billing" })}
              </span>
            </td>
            <td>
              <span className="detailsStyle">
                {intl.formatMessage({
                  id: "contact.contactDetails.billingAddress",
                })}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="detailsName">
                {intl.formatMessage({
                  id: "contact.contactDetails.romaniaOffice",
                })}
              </span>
            </td>
            <td>
              <span className="detailsStyle">
                {intl.formatMessage({
                  id: "contact.contactDetails.romaniaOfficeAddress",
                })}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="detailsName">
                {intl.formatMessage({
                  id: "contact.contactDetails.polandOffice",
                })}
              </span>
            </td>
            <td>
              <span className="detailsStyle">
                {intl.formatMessage({
                  id: "contact.contactDetails.polandOfficeAddress",
                })}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="detailsName">MAIL:</span>
            </td>
            <td>
              <span className="detailsStyle">contact@competdac.ro</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="detailsName">
                {intl.formatMessage({ id: "contact.contactDetails.duns" })}
              </span>
            </td>
            <td>
              <span className="detailsStyle">565502592</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="detailsName">
                {intl.formatMessage({ id: "contact.contactDetails.cage" })}
              </span>
            </td>
            <td>
              <span className="detailsStyle">1D06L</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="detailsName">
                {intl.formatMessage({ id: "contact.contactDetails.vat" })}
              </span>
            </td>
            <td>
              <span className="detailsStyle">RO26547827</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ContactDetails
