import React from "react"
import { Formik, Field, ErrorMessage } from "formik"
import { graphql, useStaticQuery } from "gatsby"
import "../ContactForm/ContactForm.scss"
import "bootstrap/dist/css/bootstrap.css"
import Image from "gatsby-image"
import { Button, Form } from "react-bootstrap"
import { useIntl } from "gatsby-plugin-intl"

const MAX_INPUT_LENGTH = 50

const getLogo = graphql`
  {
    logo: file(relativePath: { eq: "logo_transparent@2x.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ContactForm = () => {
  const intl = useIntl()
  const { logo } = useStaticQuery(getLogo)
  return (
    <div>
      <Formik
        initialValues={{
          companyName: "",
          companyEmail: "",
          subject: "",
          phone: "",
          content: "",
        }}
        validate={values => {
          const errors = {}
          if (!values.companyName) {
            errors.companyName = "Company Name is required"
          } else if (values.companyName.length > MAX_INPUT_LENGTH) {
            errors.companyName = "Company Name is too long"
          }
          if (!values.companyEmail) {
            errors.companyEmail = "Email address is required"
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              values.companyEmail
            )
          ) {
            errors.companyEmail = "Invalid email address"
          }
          if (!values.subject) {
            errors.subject = "Subject is required"
          } else if (values.subject.length > MAX_INPUT_LENGTH) {
            errors.subject = "Subject is too long"
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false)
          resetForm()
          fetch("https://competdac-mail.herokuapp.com/email", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              companyName: values.companyName,
              companyEmail: values.companyEmail,
              subject: values.subject,
              companyPhone: values.phone,
              text: values.content,
            }),
          }).error(() => {
            alert("Error sending email!")
          })
        }}
      >
        {props => (
          <div className="inner-bg container">
            <div className="form-box">
              <Form
                id="formStyle"
                className="center-block"
                onSubmit={props.handleSubmit}
              >
                <div className="image_container">
                  <Image
                    fluid={logo.childImageSharp.fluid}
                    className="logo_image"
                    alt="Contact Form Logo"
                  ></Image>
                </div>
                <Form.Row>
                  <div className="form-group col-md-6">
                    <label className="labelStyle" htmlFor="companyName">
                      {intl.formatMessage({
                        id: "contact.contactForm.companyName",
                      })}
                    </label>
                    <Field
                      type="text"
                      name="companyName"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="companyName"
                      component="div"
                      className="warningMessage"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="labelStyle" htmlFor="companyEmail">
                      {intl.formatMessage({
                        id: "contact.contactForm.companyEmail",
                      })}
                    </label>
                    <Field
                      type="email"
                      name="companyEmail"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="companyEmail"
                      component="div"
                      className="warningMessage"
                    />
                  </div>
                </Form.Row>
                <Form.Row className="flex-column-reverse flex-md-row">
                  <div className="form-group col-md-6">
                    <label className="labelStyle" htmlFor="subject">
                      {intl.formatMessage({
                        id: "contact.contactForm.subject",
                      })}
                    </label>
                    <Field
                      type="text"
                      name="subject"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="subject"
                      component="div"
                      className="warningMessage"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="labelStyle" htmlFor="phone">
                      {intl.formatMessage({
                        id: "contact.contactForm.companyPhone",
                      })}
                    </label>
                    <Field type="tel" name="phone" className="form-control" />
                  </div>
                </Form.Row>
                <Form.Row>
                  <div className="form-group col-md-12">
                    <Field
                      component="textarea"
                      rows="5"
                      type="text"
                      name="content"
                      className="form-control"
                      style={{ resize: "none", marginTop: "1.7%" }}
                    />
                  </div>
                </Form.Row>
                <div id="sendButtonStyle">
                  <Button className="btn-fill center-block" type="submit">
                    {intl.formatMessage({ id: "contact.contactForm.send" })}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default ContactForm
