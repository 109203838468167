import React from "react"
import * as styles from "./global.module.scss"
import Contact from "../components/Contact/Contact"
import { Helmet } from "react-helmet"
import GoogleTagManager from "../components/GoogleTagManager/GoogleTagManager"

const ContactPage = () => {
  return (
    <div>
      <GoogleTagManager />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Competdac - Contact</title>
        <meta
          name="description"
          content="Where to find the company, where to contact one of the employees."
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Helmet>
      <div className={styles.container}>
        <Contact></Contact>
      </div>
    </div>
  )
}

export default ContactPage
